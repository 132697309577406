import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import * as $ from "jquery";


@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.css']
})
export class PrivateComponent {

  public menuNormal;
  
  title:string = "Procurement Purchasing";
  Rightcol: string = screen.height + 'px';
  public user : any;
  public user_access : any;
  constructor(private route: Router,
    private auth :AuthService
    ) {
      this.user = auth.getDecodedToken();
      this.user_access = this.user.user_access;
      if ( auth.getIntenalToken() == undefined){
        this.route.navigate(['/signin']);
      }

      //console.log(auth.meencode('Permission.PR.Update','pro'));
      //console.log('UGVybWlzc2lvY0hKdnBybw$$bi5QUi5VcGRhdGVZMGhLZG5CeWJ3JCQ#', auth.medecode('UGVybWlzc2lvY0hKdnBybw$$bi5QUi5VcGRhdGVZMGhLZG5CeWJ3JCQ#','pro'));

  }

  toggleClicked1(event: MouseEvent) {
    var body = $("#navmenu");
    var menu = $("#sidebar-menu");
    
    // toggle small or large menu
    if (body.hasClass("nav-md")) {
      menu.find("li.active ul").hide();
      menu
        .find("li.active")
        .addClass("active-sm")
        .removeClass("active");
    } else {
      menu.find("li.active-sm ul").show();
      menu
        .find("li.active-sm")
        .addClass("active")
        .removeClass("active-sm");
    }
    body.toggleClass("nav-md nav-sm");
  }


  backtoportal(){
    //this.auth.logout();
    if ( this.user.ui.home != undefined ){

      window.location.href = this.user.ui.home;
    }
    return;
    //console.log(this.user.ui.logoff);
  }

  changePassword(){
    this.auth.logout();
    if ( this.user.ui.changepassword != undefined ){
      window.location.href = this.user.ui.changepassword;
    }
    return;
    //console.log(this.user.ui.logoff);
  }


  logout(){
    this.auth.logout();
    if ( this.user.ui.logoff != undefined ){
      window.location.href = this.user.ui.logoff;
    }
    return;
    //console.log(this.user.ui.logoff);
  }
  
  ngDoCheck() {
    console.log('check');
  }
}
