import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageComponent } from './layouts/sample/page/page.component';
import { PrivateComponent } from './layouts/private/private/private.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { CustomNavComponent } from './components/custom-nav/custom-nav.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgFor, registerLocaleData } from '@angular/common';
import localeId from '@angular/common/locales/id'; 
registerLocaleData(localeId, 'id'); 

@NgModule({
  declarations: [
    AppComponent,
    PageComponent,
    PrivateComponent,
    MainPageComponent,
    CustomNavComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
   
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgFor
  ],
  providers: [{provide: LOCALE_ID, useValue: "id-ID"}],
  bootstrap: [AppComponent]
})
export class AppModule { }
