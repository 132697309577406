import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
//import * as jwt_decode from 'jsonwebtoken';
import { jwtDecode } from "jwt-decode";
import * as C from '../services/conf.json';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  //private apiUrl = 'http://localhost:8080/index.php/'; // Replace with your API URL
   // private apiUrl = 'https://tbi-purchasing-api-dev.intishaka.com/frontend/web/index.php/'; //local
    public apiUrl = Object(C).urlApi;
 
  _httpOptionsparams(params:any){
    return {
        headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        //'Authorization' : 'Bearer '+ this.authservice.getToken()
        //'Authorization' : 'Bearer MSzNT2Jk-mILyGIVk9lcjxHUc2HKfi1L',
        }),
        
    }; 
  }
  
  constructor(private http: HttpClient) {}

  login(username: string, portal_user_id: string, user_access:any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'apis/loginbyportal', {username : username, portal_user_id:portal_user_id,role:user_access},this._httpOptionsparams({}));
  }

  accessLocal(portal:any){
    this.login(portal.profile.e_number, portal.profile.user_id, portal.user_access).subscribe((response: any) => { 
      //console.log(response);
      if (response.status) {
       this.setIntenalToken(response.data.access_token);
       //this.route.navigate(['/dashboard']);
     }
       //this.totalCount = response.totalCount;
     })
  }
  
  logout(): void {
    localStorage.removeItem('purlogtoken');
    localStorage.removeItem('ue');
    localStorage.removeItem('nestedObject');
    localStorage.removeItem('tiktok');
    localStorage.removeItem('atox');
    localStorage.removeItem('goexit');
    

  }

  getToken(): any {
    return localStorage.getItem('nestedObject');
  }

  getDecodedToken(): any {
    const token = this.getToken();
    //console.log(token);
    if ( token != undefined ){
      return jwtDecode(token);
    }else{
      return undefined;
    }
  }

  getIntenalToken(): any {
    return localStorage.getItem('tiktok');
  }

  setIntenalToken(internalToken): any {
    return localStorage.setItem('tiktok', internalToken);
  }

  setIntenalPermission(permission): any {
    return localStorage.setItem('atox', JSON.stringify(permission));
  }

  getInternalPermission(){
    return localStorage.getItem("atox");
  }
  permission : any = {};
  myPermission(p:any){
    this.permission = new BehaviorSubject(JSON.parse(localStorage.getItem('atox') ?? '[]'));
    let key = this.meencode(p,'pro');
    let output = this.permission._value;
    if (output[key] == 1){
      return true;
    }else{
      return false;
    }
  }

  isLoggedIn(): boolean {
    const token = this.getToken();
    return !!token;
  }

  meencode(word:any,key:any){
    let bkey = btoa(key);
    let bkey2 = btoa(bkey+key);
    //return bkey2;
    let keycode = bkey2.replace(/=/g, "$");
    let e1 = btoa(word+keycode);
    let olde1 = e1.replace(/=/g,'#');

    let oldencode =  keycode;
    let len = keycode.length;
    let lenold = olde1.length;

    let fencode = olde1.substring(0,len);
    let scode = olde1.substring(len,lenold);
    let combine = fencode+keycode+scode;

    return combine;
  }

  medecode(code:any,key:any){
    let bkey = btoa(key);
    let bkey2 = btoa(bkey+key);
    //return bkey2;
    let keycode = bkey2.replace(/=/g, "$");
    //return keycode;
    let len = keycode.length;

    let backoldcode = code.replace(keycode,'');

    let bc = backoldcode.replace('#','=');
    //return bc;
    let bc2= atob(bc);
    return bc2.replace(keycode,'');
  }
}