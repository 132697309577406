import { Component } from '@angular/core';
import {  Router } from '@angular/router';
import * as $ from "jquery";

@Component({
  selector: 'app-custom-nav',
  templateUrl: './custom-nav.component.html',
  styleUrls: ['./custom-nav.component.css']
})
export class CustomNavComponent {

  isopenmenu:any= [];
  constructor(private route: Router) {
    //console.log(this.route.url);
  }
  ngOnInit() {
    
   // console.log(this.route.url); // ActivatedRoute
  }
  openmenu(menu:any){
    if (!this.isopenmenu[menu])
      this.isopenmenu[menu] = true;
    else{
      this.isopenmenu[menu] = false;
    }
    console.log(this.isopenmenu);
  }

  anchorClicked(event: MouseEvent, id :any) {
    console.log('clicked');
    var body = $("#navmenu");
    console.log(body.hasClass("nav-md"));
    let $li = $("#" + id).parent();

    if ($li.is(".active")) {
      console.log($("ul:first", $li).hasClass("open"));
      if ( $("ul:first", $li).hasClass("open") ){
        $li.removeClass("active active-sm");
        $("ul:first", $li).removeClass("open");
        $("ul:first", $li).slideUp(function() {});
      }else{
        //$li.addClass("active");
        $("ul:first", $li).addClass("open");
        $("ul:first", $li).slideDown(function() {});
      }
      
    } else {
      // prevent closing menu if we are on child menu
      if (!$li.parent().is(".child_menu")) {
        $("#sidebar-menu")
          .find("li")
          .removeClass("active active-sm");
        $("#sidebar-menu")
          .find("li ul")
          .slideUp();

          $("#sidebar-menu")
          .find("li ul").removeClass("open");
      }

      $li.addClass("active");
      $("ul:first", $li).addClass("open");
      $("ul:first", $li).slideDown(function() {});
     
    }
  }

  clear(){
    this.isopenmenu['master'] = false
    let $sm = $("#sidebar-menu").parent();
    $sm.find("li ul").slideUp(function() {});
    $sm.find("li ul").removeClass("open");
    console.log(screen.height);
  }
}
