import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-signin',
  standalone: true,
  imports: [],
  templateUrl: './signin.component.html',
  styleUrl: './signin.component.css'
})
export class SigninComponent {

  public portaluser : any;
  constructor(private route: Router,
    //public s: MasterService,
    private auth :AuthService
    ) {
      this.portaluser = auth.getDecodedToken();
      //console.log(this.portaluser);
      if ( this.portaluser == undefined ){
        window.location.href = 'https://tbi-portal-dev.intishaka.com/backend/web/site/login';
      }
   // console.log(this.route.url);
   
  }

  ngOnInit(){
    this.checkInternalToken();
    console.log('masuk');
  }

  checkToken(){
    
  }

  checkInternalToken(){
    console.log(this.auth.getIntenalToken());
    if ( this.auth.getIntenalToken() == undefined){
      //console.log('lohin');
      this.auth.login(this.portaluser.profile.e_number, this.portaluser.profile.user_id, this.portaluser.user_access).subscribe((response: any) => { 
       //console.log(response);
       if (response.status) {
        this.auth.setIntenalToken(response.data.access_token);
        //console.log( response.data.permission);
        let permission = response.data.permission;
        
        this.auth.setIntenalPermission(permission);
        
        this.route.navigate(['/dashboard']);
      }
        //this.totalCount = response.totalCount;
      })
    }else{
      this.route.navigate(['/dashboard']);
      console.log('ok');
    }
  }
}
