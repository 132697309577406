import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { inject } from '@angular/core';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService)
  const tokenservice = authService.getToken(); 
  const tokenInternalservice = authService.getIntenalToken(); 
  const router = inject(Router);
  //console.log('token',tokenservice)
  //console.log('tokenInternal',tokenInternalservice)
  
  if ( tokenservice && tokenInternalservice) {
    return true;
  } else {
    // Redirect to the login page if the user is not authenticated
    router.navigate(['/signin']);
    return false;
  }

  return true;
};
